<template>
  <page-layout title="Post" linkDefault="/" :backButton="true">
   <Loading v-if="!post" />
    <div v-else-if="!loading && post" class="m-3" >
    <post :post="post" :commentRedirect="true" :viewIcon="false" />
    <!-- <post-pop-up :editable="isCurrentUser" :active="showModal" :initial="post"
      :key="update + this.$store.state.postPopup.update" /> -->
    <comment v-for="(_comment,i) in post.comments"  :key="i" :comment="_comment" :owner="isCurrentUser" />
    </div>
  </page-layout>
</template>

<script>
import Loading from "../components/Loading.vue";
import { getPostbyId } from "../helpers/API/Feeds/Post";
import Comment from "../components/Feed/Comment.vue";
import Post from "../components/Feed/Post.vue";
import pageLayout from "../components/base/pageLayout.vue";
import Button from "../components/Feed/Button.vue";
import PostPopUp from "../components/PostPopUp.vue";
import { getCommentsbyPostId } from "../helpers/API/Feeds/Comment";
export default {
  components: { Post, pageLayout, Button, PostPopUp, Comment,Loading },
  data() {
    return {
      postid: null,
      post: null,
      user: null,
      isCurrentUser: false,
      showModal: false,
      update: 0,
      comments: [],
      loading: true,
    }
  },
  created() {
    this.getPost();
    
  },
  methods: {
    async getPost() {
      getPostbyId(this.$route.params.postId)
        .then(({data}) => {
          this.post = data.data[0];
          this.user = this.post.user;
          this.loading = false;
          this.update++;
          this.isCurrentUser = this.$store.state.profile?.user_id === this.post.user_id;
        })
        this.loading = false;
    },
    postpopup() {
      this.showModal = true;
      this.update++;
    },
    getComments(id) {
      getCommentsbyPostId(id).then((data) => {
        this.comments = data.data;
      });
    },
  },
  watch: {
    "$route.params.postId": {
      handler: function () {
        if (this.$route.params.postId)
          this.getPost()
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
