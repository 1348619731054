<template>
  <div v-if="comment.deleted_at === null" class="flex flex-col w-full p-3" :key="update">
    <div class="absolute overlay flex flex-row items-end justify-end mx-64 -my-6 ">
      <div class="px-5 pt-7" id="controls">
        <div  class="text-left inline-flex flex-col w-20">
          <button :key="updatePopUp" @click="(optionsPopUp)" class="p-0 text-gray-300 bg-transparent z-10 ">
            •••
          </button>
          <div class="border-orange bg-white w-52 -mx-36 z-30" :class="{ 'hidden': !open }">
            <div class="rounded-3xl shadow-2xl p-2">
              <a v-if="owner || canDelete()" @click="deletecomment()" class="block border-b border-gray-200">
                <div class="flex flex-row p-2 space-x-1 justify-between rounded-full">
                  <div class="font-inter text-xs text-red-600">Delete</div>
                  <vue-feather size="20" stroke="red" type="trash-2"></vue-feather>
                </div>
              </a>
              <a v-if="!owner && canDelete() || owner & canDelete()" @click="openCommentPopUp1()"
                class="block border-b border-gray-200">
                <div class="flex flex-row p-2 space-x-11 justify-between">
                  <div class="font-inter text-xs">Edit</div>
                  <vue-feather size="20" type="edit-2"></vue-feather>
                </div>
              </a>
              <a v-if="!canDelete()" @click="report()" class="block border-b border-gray-200">
                <div class="flex flex-row p-2 space-x-11 justify-between">
                  <div class="font-inter text-xs">Report</div>
                  <vue-feather size="20" type="flag"></vue-feather>
                </div>
              </a>
              <a v-if="owner" @click="approve()" class="block border-gray-200">
                <div class="flex flex-row p-2 space-x-11 justify-between">
                  <div class="font-inter text-xs">{{ approved ? 'Unapprove' : 'Approve' }}</div>
                  <vue-feather size="20" type="check-circle"></vue-feather>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-between ">
      <Avatar class="content-center" :user="comment.user" :name="true" :username="true" size="small" />
      <!-- <vue-feather v-if="owner || canDelete()" :stroke='currentColor' size="16" type="trash-2"
          @click="deletecomment"></vue-feather> -->
    </div>
    <div class="flex mx-10">
      <p class="
              text-black
              font-poppins
              leading-6
              font-normal
              -my-3
              text-xs text-left
            ">
        {{ comment.text }}
      </p>
    </div>
    <div v-if="owner || approved" class="flex flex-row items-end justify-end mr-3 pt-2 text-gray-300 space-x-3 h-6">
      <vue-feather :stroke="approved ? 'orange' : 'currentColor'" size="16" type="check-circle"
        @click="approve"></vue-feather>
      <span v-if="approved" class="text-primary text-xs ml-12">Approved</span>
      <!-- <vue-feather size="16" type="corner-up-left" @click="openReplyPopup"></vue-feather> -->
    </div>
  </div>
  <CommentPopUp :Edited="true" v-if="openCommentPopUp" :text="this.comment.text" :user="this.comment.user"
    :postId="this.comment.post_id" :commentId="this.comment.id" />
  <div>
    <hr class="w-full" />
  </div>
</template>

<script>
import { mapMutations } from "vuex";

import {
  approveComment,
  unAprroveComment,
  deleteComment,
  reportComment
} from "../../helpers/API/Feeds/Comment";
import CommentPopUp from "../CommentPopUp.vue";
import Avatar from "./Avatar.vue";
export default {
  components: { Avatar, CommentPopUp },
  props: {
    comment: {
      type: Object,
      default: {},
    },
    owner: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      approved: false,
      update: 0,
      open: false,
      openCommentPopUp: false,
      updatePopUp: 0,
    };
  },
  created() {
    console.log(this.comment)
    this.ifApproved();
  },
  methods: {
    ...mapMutations(["fireToast"]),
    ifApproved() {
      if (this.comment.comments_approves_count == 1) {
        this.approved = true;
      }
    },
    canDelete() {
      if (this.comment.user.username === this.$store.state.user.username) {

        return true;
      }
    },

    approve() {
      if (this.owner) {
        this.approved = !this.approved;
        if (this.approved) {
          approveComment(this.comment.id);
          this.fireToast({
            text: `Comment Approved`,
            type: "success",
          });
          this.openCommentPopUp = false;
          this.update++;
        } else {
          unAprroveComment(this.comment.id);
          this.fireToast({
            text: `Comment Unapproved`,
            type: "success",
          });
          this.openCommentPopUp = false;
          this.update++;
        }
      }
    },
    openCommentPopUp1() {
      this.openCommentPopUp = !this.openCommentPopUp;
    },
    optionsPopUp() {
      this.open = !this.open;
    },
    async deletecomment() {
      if (confirm("Are you sure you want to delete this comment?")) {
        await deleteComment(this.comment.id).then(() => {
          this.update++;
          this.fireToast({
            text: `Comment Deleted`,
            type: "success",
          });
        })
        this.$router.go()

      }
    },
    async report() {
      await reportComment(this.comment.id).then(() => {
        this.fireToast({
          text: `Comment Reported`,
          type: "success",
        });
        this.openCommentPopUp = false;
        this.update++;
      })
    }
  },
};
</script>
